.menuArea {
    width: 295px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
}

.menuAreaStart {
    width: 295px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 80px;
}


.confirmWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 1px;
    width: 300px;
    /*height: 283px;*/
    border-radius: 16px;
    box-shadow: 0 12px 26px rgba(33, 39, 49, 0.1);
    filter: drop-shadow(0px 12px 26px rgba(33, 39, 49, 0.1));
    background-color: white;
}

@media (hover: hover) {
    .popupWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 1px;
        position: absolute;
        width: 300px;
        /*height: 283px;*/
        /*left: 40%;*/
        top: 30%;
        left: 40%;
        border-radius: 16px;
        box-shadow: 0 12px 26px rgba(33, 39, 49, 0.1);
        filter: drop-shadow(0px 12px 26px rgba(33, 39, 49, 0.1));
        background-color: white;
    }
}


@media (hover: none) {
    /* hover 불가능한 경우 모바일 */
    .popupWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 1px;
        position: absolute;
        width: 300px;
        /*height: 283px;*/
        /*left: 40%;*/
        top: 30%;
        border-radius: 16px;
        box-shadow: 0 12px 26px rgba(33, 39, 49, 0.1);
        filter: drop-shadow(0px 12px 26px rgba(33, 39, 49, 0.1));
        background-color: white;
    }
}


.closeBtn {
    cursor: pointer;
}

.popupTitleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 11px 24px;
}

.popupTitle {
    width: 250px;
    /*font-family: "Spoqa Han Sans Neo";*/
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #111111;
}

.popupLine {
    width: 98%;
    height: 0;
    border: 1px solid #f3f4f6;
    flex: none;
    margin: 0 0 20px;
}

.popupBody {
    display: flex;
    flex-direction: column;
    width: 270px;
    margin-bottom: 20px;
    font-size: 14px;
    justify-content: center;
    max-height: 250px;
    overflow: auto;
    text-align: center;
}

.chatBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.ConfirmBtn {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
}

.ConfirmBtn a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 80px;
    height: 38px;
    background: #4f46e5;
    border-radius: 60px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
}

.confirmBtnWrap {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.ConfirmBtnOk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 40%;
    max-width: 120px;
    height: 48px;
    background: #4f46e5;
    border-radius: 60px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    float: right;
}

.AlertBtnWrap {
    /*padding-top: 20px;*/
    padding-bottom: 20px;
}

.ConfirmBtnCancel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 40%;
    max-width: 120px;
    height: 48px;
    background: #8b8a9b;
    border-radius: 60px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    float: left;
}


.ConfirmBtnAlert {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 80px;
    height: 48px;
    background: #4f46e5;
    border-radius: 60px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    float: left;
}

.AlertWrapper {
    display: flex;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    width: 100%;
    height: 100vh;
    background: rgb(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(5px);
    z-index: 102;

}
div.react-confirm-alert-overlay {
    z-index: 9999;
}