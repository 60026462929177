
* {
  font-family: "SFProText","Pretendard-Regular" !important;
}


img {
  vertical-align: middle;
}

@mixin flexRow {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
}

@mixin flexColumn {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rootContainer {
  box-sizing: border-box;
  width: 100%;
  padding: 65px 16px 80px 16px;
  background: rgba(242, 243, 246, 1);
  backdrop-filter: blur(10px);
}

.bannerWrapper {
  @include flexRow;
  padding: 8px 15px 8px 8px;
  background-color: #DEE9FD;
  justify-content: space-between;
}

.title {
  @include flexRow;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.flexColumn {
  @include flexColumn;
  align-items: flex-start;
  justify-content: center;
}

.pageTitle {
  @include flexColumn;
  position: fixed;
  top: 0px;
  z-index:10;
  margin-left: -20px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  min-width: 360px;
  max-width: 412px;
}

.pageTitleBlack {
  @include flexColumn;
  position: fixed;
  top: 0px;
  z-index:10;
  margin-left: -20px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  min-width: 360px;
  max-width: 412px;
  background-color: #000000;
}

.mainTitle {
  @include flexRow;
  position: fixed;
  top: 0px;
  box-shadow: none;
  justify-content: space-between;
  align-items: center;
  background: rgba(242, 243, 246, 1);
  padding:0px 20px;
  z-index: 90;
  min-width: 360px;
  max-width: 412px;
}
.flexColumEnd {
  @include flexColumn;
  align-items: flex-end;
  justify-content: center;
}

.flexRowSpace {
  @include flexRow;
  justify-content: space-between;
  align-items: center;
}

.flexRowCenter {
  @include flexRow;
  justify-content: center;
  align-items: center;
}

.flexRowEnd {
  @include flexRow;
  justify-content: flex-end;
  align-items: center;
}

.flexRowStart {
  @include flexRow;
  justify-content: flex-start;
  align-items: center;
}


.flexRowAround {
  @include flexRow;
  justify-content: space-around;
  align-items: center;
}

.closeButton10 {
}

.mainBox {
  @include flexColumn;
  align-items: flex-start;
  padding: 20px 20px 20px 20px;
  width: 100%;

  background: #FFFFFF;
  border-radius: 20px;
  margin-bottom: 16px;
}

.mainBox .titleContainer {
  @include flexRow;
  justify-content: space-between;
  align-items: center;
  height: 28px;
}


.mainBox .contentsBetween {
  @include flexRow;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.mainBox .contentsColumn {
  @include flexColumn;
  align-items: center;
  margin-top: 12px;
}

.mainBox .contentsCenter {
  @include flexRow;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
}

.mainBox .contentsStart {
  @include flexRow;
  margin-top: 12px;
  justify-content: flex-start;
  align-items: center;
}

/* 버튼 스타일 */
.mainCryptoBadge {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 30px;
  align-self: stretch;
  flex-grow: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 12px 5px 8px;
  border-radius: 100px;
  border: solid 1px rgba(204, 207, 209, 0.3);
  background-color: #e7e9ed;
  gap: 5px;
}

.mainWalletBadge {
  @include flexRow;
  width: 200px;
  height: 34px;
  justify-content: space-between;
  align-items: center;
  padding: 7px 8px 7px 12px;
  border-radius: 100px;
  border: solid 1px #dbdee6;
  //gap: 5px;
}

.tokenEventBadge {
  @include flexColumn;

  justify-content: center;
  align-items: flex-start;
  padding: 12px 15px;
  border-radius: 12px;
  background-color: #ebf2ff;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  color:#5a6168;
}
.tokenEventText {
  flex-grow: 0;
  font-family: SFProText,"Noto Sans";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.8px;
  text-align: left;
  color: #5a6168;
}
.tokenListSubBadge {
  @include flexColumn;
  height: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 100px;
  background-color: #f2f4f6;
}
.textAreaBox2 {
  width: 100%;
  height: 58px;
  flex-grow: 0;
  border: 0;
  border-bottom: solid 1px #f2f4f6;
  font-family: SFProText,"Noto Sans";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: left;
  color:#111111;
  resize: none;

}

.textAreaBox {
  width: 100%;
  height: 58px;
  flex-grow: 0;
  font-family: SFProText,"Noto Sans";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: left;
  color:#111111;
  resize: none;
}
.textAreaBox::placeholder{
  color:#e7e9ed;
}

.textAreaGrayBox {
  width: 100%;
  height: 58px;
  flex-grow: 0;
  font-family: SFProText,"Noto Sans";
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: left;
  color:#D2D6D9;
  resize: none;
  background-color: #F8F9FC;
}
.textAreaGrayBox::placeholder{
  color:#D2D6D9;
}

input[type="text"] {
  color: #111111;
}

input[type="text"]::placeholder {
  color: #e7e9ed;
}


input[type="number"] {
  color: #111111;
}

input[type="number"]::placeholder {
  color: #e7e9ed;
}



.textBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 10px 16px;
  border-radius: 8px;
  border: solid 1px #f2f4f6;
  background-color: #ffffff;
  font-size: 13px;
  color: #5a6168;
}


@mixin imageBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 104px;
  padding: 20px 12px;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 14px;
  color: #5a6168;
  gap: 8px;
}
.imageBoxActive {
  @include imageBox;
  border: solid 2px #3a71ff;
}

.imageBoxDefault {
  @include imageBox;
  border: solid 1px #f2f4f6;
}
.copy {
  box-sizing: border-box;
  text-align: center;
  padding: 5px 16px;
  border-radius: 8px;
  border: 1px solid #f2f4f6;
  margin-left: 10px;
  font-size: 13px;
  color: #5a6168;
}

/* 여백 정리 */
.lp8 {
  padding-left: 8px;
}

.lp2 {
  padding-left: 2px;
}

.line {
  height: 1px;
  align-self: stretch;
  flex-grow: 0;
  background-color: #f2f4f8;
}


/*TEXT 스타일 정의*/
.txtContent {
  font-size: 13px;
  color: #838B96;
}

.txtTitle18 {
  font-weight: 400;
  font-size: 18px;
  color: #111111;
}

.txtTitle15 {
  font-weight: 400;
  font-size: 15px;
  color: #111111;
}

.txtTitle13 {
  font-weight: 400;
  font-size: 13px;
  color: #4b4f55;
}
.homeTitle13{
  width: 45px;
  font-weight: 400;
  font-size: 13px;
  color: #4b4f55;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.txtZigleCoin {
  font-size: 26px;
  color: #111111;

}

.txtNoContent {
  font-size: 14px;
  color: #838B96;
}

.txtCryptoAddr {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  color: #5a6168;
}
.txt10_B01 {
  font-size: 10px;
  font-weight: 400;
  color: #000000;
}
.txt10_B06 {
  font-size: 10px;
  font-weight: 400;
  color: #ABB5C2;
}
.txt11_W {
  font-size: 11px;
  font-weight: 400;
  color: #FFFFFF;
}

.txt11_PointB {
  font-size: 11px;
  font-weight: 400;
  color: #3A71FF;
}
.txt11_B03 {
  font-size: 11px;
  font-weight: 400;
  color: #4B4F55;
}
.txt11_B04 {
  font-size: 11px;
  font-weight: 400;
  color: #5a6168;
}

.txt12_B05 {
  font-size: 12px;
  font-weight: 400;
  color: #838b96;
}
.txt12_B06 {
  font-size: 12px;
  font-weight: 400;
  color: #ABB5C2;
}
.txt12_BFC7DC {
  font-size: 12px;
  font-weight: 400;
  color: #BFC7DC;
}

.txt12_200_B05 {
  font-size: 12px;
  font-weight: 200;
  color: #838b96;
}
.txt12_W01 {
  font-size: 12px;
  font-weight: 400;
  color: #F2F4F6;
}
.txt12_W03 {
  font-size: 12px;
  font-weight: 400;
  color: #d2d6d9;
}
.txt12_300_B05 {
  font-size: 12px;
  font-weight: 300;
  color: #838b96;
}

.txt12_200_B04 {
  font-size: 12px;
  font-weight: 200;
  color: #5a6168;
}
.txt12_B02 {
  font-size: 12px;
  font-weight: 400;
  color: #2F3236;
}
.txt12_B04 {
  font-size: 12px;
  font-weight: 400;
  color: #5a6168;
}

.txt12_300_B04 {
  font-size: 12px;
  font-weight: 300;
  color: #5a6168;
}
.txt13_300_B {
  font-size: 13px;
  font-weight: 300;
  color: #000000;
}
.txt13_W{
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
}
.txt13_W03{
  font-size: 13px;
  font-weight: 400;
  color: #D2D6D9;
}
.txt13_B {
  font-size: 13px;
  font-weight: bold;
  color: #000000;
}

.txt13_B01 {
  font-size: 13px;
  color: #111111;
}
.txt13_B06 {
  font-size: 13px;
  color: #ABB5C2;
}
.txt13_500_B02 {
  font-size: 13px;
  font-weight: 500;
  color: #2F3236;
}
.txt13_500_B05 {
  font-size: 13px;
  font-weight: 500;
  color: #838b96;
}

.txt13_600_B {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.txt13_600_B05 {
  font-size: 13px;
  font-weight: 600;
  color: #838b96;
}

.txt13_300_B05 {
  font-size: 13px;
  font-weight: 300;
  color: #838b96;
}

.txt13_500_B04 {
  font-size: 13px;
  font-weight: 500;
  color: #5a6168;
}
.txt13_600_B04 {
  font-size: 13px;
  font-weight: 600;
  color: #5a6168;
}
.txt13_B04 {
  font-size: 13px;
  font-weight: 400;
  color: #5a6168;
}

.txt13_mint {
  font-size: 13px;
  font-weight: 400;
  color: #53cec7;
}

.txt13_red {
  font-size: 13px;
  font-weight: 400;
  color: #f01d1d;
}
.txt13_Crimson {
  font-size: 13px;
  font-weight: 400;
  color: #ed6957;
}

.txt14_300_B01 {
  font-size: 14px;
  font-weight: 300;
  color: #111111;
}
.txt14_800{
  font-size: 14px;
  font-weight: 800;
}
.txt14_B01 {
  font-size: 14px;
  font-weight: 400;
  color: #111111;
}
.txt14_B02 {
  font-size: 14px;
  font-weight: 400;
  color: #2f3236;
}

.txt14_300_B01 {
  font-size: 14px;
  font-weight: 300;
  color: #111111;
}

.txt14_B04 {
  font-size: 14px;
  color: #5a6168;
}

.txt14_BOLD_B04 {
  font-size: 14px;
  color: #5a6168;
  font-weight: 600;
}


.txt15_B01 {
  font-size: 15px;
  color: #111111;
}
.txt15_B03 {
  font-size: 15px;
  color: #4B4F55;
}

.txt15_600_B03 {
  font-size: 15px;
  color:#4B4F55;
  font-width: 600;
}
.txt15_500_B01 {
  font-size: 15px;
  font-weight: 500;
  color: #111111;
}

.txt15_B02 {
  font-size: 15px;
  color: #2f3236;
}

.txt15_500_B02 {
  font-size: 15px;
  font-weight: 500;
  color: #2f3236;
}
.txt14_W03 {
  font-size: 14px;
  color: #d2d6d9;
}

.txt15_W03 {
  font-size: 15px;
  color: #d2d6d9;
}

.txt15_B05 {
  font-size: 15px;
  color: #838b96;
}

.txt16 {
  font-size: 16px;

}


.txt16_B02 {
  font-size: 16px;
  color: #2f3236;
}

.txt16_500_B02 {
  font-size: 16px;
  color: #2f3236;
  font-weight: bold;
}

.txt16_500_PointB{
  font-size: 16px;
  font-weight: 500;
  color: #3a71ff;
}
.txt16_B05 {
  font-size: 16px;
  color: #838b96;
}

.txt17_600_mint {
  font-size: 17px;
  font-weight: 600;
  color: #53cec7;
}

.toastyfiNoti {
  --toastify-toast-background: rgba(90, 97, 104, 0.8);
  --toastify-color-light: rgba(90, 97, 104, 0.8);
  border-radius: 12px !important;
  width:320px !important;
  justify-content: center;
  margin:20px auto !important;
  align-items: center;
  height:56px !important;
}
.toastyfiNotiBody {
  border-radius: 12px;
}


.txt16_B01 {
  font-size: 16px;
  color: #111111;
}

.txt16_500_B01 {
  font-size: 16px;
  color: #111111;
  font-weight: 500;

}

.txt18_500_Point_B {
  font-weight: bold;
  font-size: 18px;
  color: #3a71ff;
}

.txt18 {
  font-weight: 400;
  font-size: 18px;
  color: #111111;
}

.txt18_500 {
  font-weight: 500;
  font-size: 18px;
  color: #111111;
}

.txt18_500_B02 {
  font-weight: 500;
  font-size: 18px;
  color: #2f3236;
}

.txt18_500_W {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.txt18_B02 {
  font-weight: 400;
  font-size: 18px;
  color: #2f3236;
}

.txt18_500_PointB{
  font-size: 18px;
  font-weight: 500;
  color: #3a71ff;
}
.txt18_700_B01{
  font-size: 18px;
  font-weight: 700;
  color: #111111;
}


.txt20_B01 {
  font-size: 20px;
  color: #111111;
}

.txt20_600_B05 {
  font-size: 20px;
  color: #838B96;
  font-width: 600;
}

.txt20_600_W {
  font-size: 20px;
  font-width: 600;
  color:#FFFFFF;
}

.txt20_W03 {
  font-size: 20px;
  color:#d2d6d9;
}

.txt20_Stroke{
  font-size: 20px;
  color:#dbdee6;
}

.txt20_600_B01 {
  font-size: 20px;
  color: #111111;
  font-weight: 600;
}

.txt20_500_B01 {
  font-size: 20px;
  font-weight: 500;
  color: #111111;
}

.txt20_700_B01 {
  font-size: 20px;
  font-weight: 700;
  color: #111111;
}
.txt13_B02 {
  font-size: 13px;
  font-weight: normal;
  color: #2F3236;
}
.txt13_B05 {
  font-size: 13px;
  font-weight: normal;

  color: #838b96;
}

.txt15_B01 {
  font-size: 15px;
  font-weight: 400;

  color: #111111;
}

.txt15_Crimson {
  font-size: 15px;
  font-weight: 400;
  color: #ed6957;
}

.txt15_500_B01 {
  font-size: 15px;
  font-weight: 500;

  color: #111111;
}

.txt15_B02 {
  font-size: 15px;
  font-weight: 400;

  color: #2f3236;
}

.txt16_B02 {
  font-size: 16px;
  font-weight: 400;

  color: #2f3236;
}

.txt16_B04 {
  font-size: 16px;
  color: #5a6168;
}

.txt16_B05 {
  font-size: 16px;
  font-weight: 400;
  color: #838b96;
}

.txt16_W03 {
  font-size: 16px;
  font-weight: 400;
  color: #d2d6d9;
}
.txt14_B03 {
  font-size: 14px;
  font-weight: 400;
  color: #4b4f55;
}

.txt14_B04 {
  font-size: 14px;
  font-weight: 400;
  color: #5a6168;
}
.txt14_Point_B {
  font-size: 14px;
  font-weight: 400;
  color: #3a71ff;
}

.txt14_B05 {
  font-size: 14px;
  font-weight: 400;
  color: #838b96;
}
.txt14_300_B04 {
  font-size: 14px;
  font-weight: 300;
  color: #5A6168;
}
.txt14_300_B05 {
  font-size: 14px;
  font-weight: 300;
  color: #838b96;
}
.txt11_B04 {
  font-size: 11px;
  font-weight: 400;

  color: #5a6168;
}
.txt12_500_PointB{
  font-size: 12px;
  font-weight: 500;
  color: #3a71ff;
}
.txt14_500_PointB{
  font-size: 14px;
  font-weight: 500;
  color: #3a71ff;
}
.txt15_PointB{
  font-size: 15px;
  font-weight: 400;
  color: #3a71ff;
}
.txt15_PointR{
  font-size: 15px;
  font-weight: 400;
  color: #F01D1D;
}
.txt24{
  font-size: 24px;
  font-weight: 400;
  color: #000000;
}

.txt24_800_W{
  font-size: 24px;
  font-weight: 800;
  color: #FFFFFF;
}

.txt32_600{
  font-size: 32px;
  font-weight: 600;
}
.txt32_600_W{
  font-size: 32px;
  font-weight: 600;
  color: #FFFFFF;
}

.txt32_800_W{
  font-size: 32px;
  font-weight: 800;
  color: #FFFFFF;
}
.txt32_350_W{
  font-size: 32px;
  font-weight: 350;
  color: #FFFFFF;
}

.txt28_B01{
  font-size: 28px;
  font-weight: 400;
  color: #111111;
}
.txt28_600_B02{
  font-size: 28px;
  font-weight: 600;
  color: #2F3236;
}
//Input

.inputText_chat {
  height: 18px;
  width: 100%;
  //color: #dbdee6;
  font-size: 14px;
  border: 0;
  color: #ABB5C2;
  background-color: #F2F4F6;
}
.inputText_chat:focus {
  outline: none;
  color:#111111;
  margin-left: 0;
  border: 0;
}
.inputText_chat::placeholder {
  color: #ABB5C2;
  font-size: 14px;
}

.inputText_18 {
  height: 18px;
  width: 100%;
  //color: #dbdee6;
  font-size: 15px;
  text-align: right;
  border: 0;
  color: #111111;
  margin-left: -3px;
}
.inputText_18:focus {
  outline: none;
  margin-left: 0;
  border: 0;
}
.inputText_18::placeholder {
  color: #dbdee6;
  font-size: 15px;
}

.inputText_33 {
  height: 33px;
  width: 100%;
  //color: #dbdee6;
  font-size: 20px;
  text-align: left;
  border: 0;
  color: #111111;
  margin-left: -3px;
}
.inputText_33:focus {
  outline: none;
  margin-left: 0;
  border: 0;
}
.inputText_33::placeholder {
  color: #dbdee6;
  font-size: 20px;
}

.inputText_44_noborder {
  height: 44px;
  width: 100%;
  //color: #dbdee6;
  font-size: 20px;
  text-align: left;
  border: 0;
  color: #111111;
  margin-left: -3px;
}
.inputText_44_noborder:focus {
  outline: none;
  margin-left: 0;
  border: 0;
}

.inputText_44_noborder::placeholder {
  color: #dbdee6;
  font-size: 20px;

}

.inputText_44 {
  height: 44px;
  width: 100%;
  //color: #dbdee6;
  font-size: 20px;
  text-align: left;
  border: 0;
  border-bottom: solid 1px #f2f4f8;
  color: #111111;
  margin-left: -3px;
}
.inputText_44:focus {
  outline: none;
  margin-left: 0;
  border: 0;
  border-bottom: solid 1px #3a71ff;
}

.inputText_44::placeholder {
  color: #dbdee6;
  font-size: 20px;
}


.inputText_44_Stroke {
  height: 44px;
  width: 100%;
  color: #dbdee6;
  font-size: 20px;
  text-align: left;
  border: 0;
  border-bottom: solid 1px #f2f4f8;
  margin-left: -3px;
}
.inputText_44_Stroke:focus {
  outline: none;
  margin-left: 0;
  border: 0;
  border-bottom: solid 1px #3a71ff;
}

.inputText_44_Stroke::placeholder {
  color: #dbdee6;
  font-size: 20px;
}

@mixin topTab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
}

.topTabDefault {
  @include topTab;
  color: #838b96;
}

.topTabActive {
  @include topTab;
  color: #111111;
  font-weight: 700;
  border-bottom: 2px solid #2f3236;
}

.modal_1251 {
  @include flexColumn;
  height: 188px;
  justify-content: flex-start;
  align-items: stretch;
  margin: 479px 0 0;
  padding: 0;
}
.img_btn {
  @include flexColumn;
  width: 93px;
  height: 34px;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  border-radius: 8px;
  border: solid 1px #f2f4f6;
  background-color: #ffffff;
  font-size: 13px;
  color: #5a6168;
  letter-spacing: -0.6px;
}
.select_mainnet {
  @include flexRow;
  height: 60px;
  align-items: center;
  padding: 16px 12px;
  border-radius: 12px;
  border: solid 1px #e3e5ed;
  background-color: #f8f9fc;
}

.selected_area {
  @include flexColumn;
  height: 34px;
  width:100%;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  border-radius: 8px;
  border: solid 1px #f2f4f8;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  color: #5a6168;
}
.wallet_logo_area {
  height: 32px;
  width:220px !important;
  align-items: center;
  gap: 4px;
  padding:0px 20px;
  border-radius: 100px;
  border: solid 1px #dbdee6;
}
.link_sub{
  @include flexColumn;
  width: 80px;
  height: 28px;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border-radius: 100px;
  background-color: #ebf2ff;
  letter-spacing: -0.6px;
  text-align: center;
}
.airdrop_sub{
  @include flexRow;
  width: 107px;
  height: 32px;
  align-items: center;
  padding: 6px 12px;
  border-radius: 100px;
  background-color: #3a71ff;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: -0.6px;
  text-align: center;
}
@mixin airdropSubTab {
  height: 16px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 13px;
  border-radius: 100px;
  font-size:13px;
  font-width: 500;
}

.airdrop_open{
  border-radius: 24px 24px 0px 0px;
  box-shadow: inset 0px 0.6px 1px #DBDEE6;
  padding: 30px 20px 10px 20px;
}
.airdropSubTabDefault {
  @include airdropSubTab;
  color: #2f3236;
  background-color:#ffffff;
  border: solid 1px #f2f4f8;
}

.airdropSubTabActive {
  @include airdropSubTab;
  color: #ffffff;
  background-color:#111111;
}
@mixin airdropTab {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
  font-size:18px;
  font-weight: 600;
}

.airdropTabDefault {
  @include airdropTab;
  color: #838b96;
}

.airdropTabActive {
  @include airdropTab;
  color: #111111;
  font-weight: 700;
}
.airdropMyItem{
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
  padding: 12px 16px 16px;
  border-radius: 16px;
  border: solid 1px #f2f4f8;
  background-color: #ffffff;
}
@mixin airdropFee{
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
  padding: 12px 16px 16px;
  border: solid 1px #f2f4f8;
}
.airdropFeeTop{
  @include airdropFee;
  border-radius: 16px 16px 0px 0px;
  background-color: #ffffff;
}
.airdropFeeBottom{
  @include airdropFee;
  border-radius: 0px 0px 16px 16px;
  background-color: #f2f4f6;
  color:#838b96;
  font-size: 13px;
}
.airdrop_now_fee{
  padding: 8px 0;
  border-radius: 8px;
  background-color:#f8f9fc;
  font-size: 12px;
  color: #838b96;
}
.airdrop_bg{
  border-radius: 20px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  //background-image: linear-gradient(to bottom, #303030 0%, #000000 100%);
  background-image: url("../../assets/imges/airdrop/airdrop_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding:40px;
  height:228px;

}

.pwa_round_bg{
  fill: #FFFFFF;
  stroke-width: 1px;
  stroke: #F1F3F6;
  background-image: url("../../assets/imges/pwa/Union.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 13px 19px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #3B3E43;
  width:166px;

}

.airdrop_bg_out{
  border-radius: 20px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  //background-image: linear-gradient(to bottom, #303030 0%, #000000 100%);
  background-image: url("../../assets/imges/airdrop/airdrop_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height:228px;
}
.airdropTransBlur{
  padding:16px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.3);
  height:148px;
}
.airdropTransBlur_out{
  padding:16px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: linear-gradient(180deg, rgba(48, 48, 48, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
  height:100%;
}
.airdropBlur {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.95);
}
.airdrop_bage_card_ing{
  flex-grow: 0;
  display: flex;
  line-height: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 6px;
  border-radius: 100px;
  border: solid 1px #dee9fd;
  background-color: #ebf2ff;
  font-size:11px;
  letter-spacing: -0.6px;
  color:#3a71ff;
}
.airdrop_bage_card_end{
  flex-grow: 0;
  display: flex;
  line-height: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 6px;
  border-radius: 100px;
  border: solid 1px #dee9fd;
  background-color: #f2f4f6;
  font-size:13px;
  letter-spacing: -0.6px;
  color:#838b96;
}
.airdrop_bage_card_before{
  flex-grow: 0;
  display: flex;
  line-height: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 6px;
  border-radius: 100px;
  border: solid 1px #CCF8F5;
  background-color: #FFFFFF;
  font-size:13px;
  letter-spacing: -0.6px;
  color:#53CEC7;
}
.airdrop_bage_time {
  display: flex;
  flex-direction: row;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #DEE9FD;
  border-radius: 8px;
  font-size:13px;
  color:#3A71FF;
}
.airdrop_bage_card_ready{
  flex-grow: 0;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 6px;
  border-radius: 100px;
  border: solid 1px #dee9fd;
  background-color: #ffffff;
  font-size:11px;
  letter-spacing: -0.6px;
  color:#3a71ff;
}
.airdrop_bage_blur{
  padding: 4px 8px 4px 6px;
  border-radius: 100px;
  border: solid 1px #dee9fd;
  font-size:11px;
  letter-spacing: -0.6px;
  color:#ffffff;
  background-color: rgba(255, 255, 255, 0.3);
}
.customDatePicker{
  border: none;
}
.progress {
  appearance: none;
}
.progress::-webkit-progress-bar {
  height:40px;
  background:#ffffff;
  border-radius:100px;
  border: solid 1px #f2f4f8;
}
.progress::-webkit-progress-value {
  border-radius:100px;
  height:32px;
  background: #000000;
}
.pwa_btn{
    width: 78px;
    height: 34px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: solid 1px #d4d8e2;
    background-color: #ffffff;
    color:#111111;
    font-size: 14px;
}
.ellipse_151{
  width: 4px;
  height: 4px;
  flex-grow: 0;
  background-color: #6c58f5;
  border-radius: 50%;
}
.profile_btn_white{

  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 32px;
  border-radius: 8px;
  border: solid 1px #dbdee6;
  background-color: #ffffff;
}

.profile_btn_black{

  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 32px;
  border-radius: 8px;
  background-color: #111111;
}
.popupBtnBlue{
  width: 70%;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 18px 32px;
  border-radius: 100px;
  background-color: #3a71ff;
  color:#ffffff;
  font-size: 16px;
}
.metamaskTabOn{
  width: 96px;
  height: 44px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #3a71ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.metamaskTabOff{
  width: 95px;
  height: 44px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  border: solid 1px #dbdee6;
  background-color: #ffffff;
  cursor: pointer;
}
.popupWalletBadge{
  @include flexRow;
  width: 40px;
  height: 60px;
  align-items: center;
  height: 8px;
  padding: 4px 8px;
  border-radius: 100px;
  background-color: #ebf2ff;
  color:#3a71ff;
  font-size:11px;
  text-align: center;
}

.txt22_B01{
  font-size: 22px;
  color: #111111;
}
.txt22_B05{
  font-size: 22px;
  color: #838B96;
}

.txt22_W{
  font-size: 22px;
  color: #ffffff;
}
.txt22_500_B01{
  font-size: 22px;
  font-weight: 500;
  color: #111111;
}
.txt22_600_B01{
  font-size: 22px;
  font-weight: 600;
  color: #111111;
}
.txt22_600_W03{
  font-size: 22px;
  font-weight: 600;
  color: #D2D6D9;
}
.txt22_500_B05{
  font-size: 22px;
  font-weight: 500;
  color: #838b96;
}
.txt17_B01{
  font-size: 17px;
  font-weight: 400;
  color: #111111;
}

/*
 * 웹 페이지 전체 체크박스를 커스터마이징 - CSS
 */

// 기본 체크박스를 안보이게 처리
input[type="checkbox"] {
  display:none;
}

// 커스터마이징 기본(unchecked인 경우)
input[type="checkbox"] + label {
  cursor: pointer; // 기본체크박스는 없으므로 추가해줌
  padding-left: 20px; // 패딩값은 21~23px가 적당
  background-repeat: no-repeat;
  width:20px;
  height:20px;
  background-size: contain;
  background-image: url('../../assets/imges/check_off.png'); // 체크박스 이미지는 라벨 태그의 배경 이미지로 표시
}

// checked인 경우
input[type="checkbox"]:checked + label {
  width:20px;
  height:20px;
  background-size: contain;
  background-image: url('../../assets/imges/check_on.png');
}


.wave{
  border:1px solid #E3E5ED;
  padding:20px;
  border-radius:20px 20px 0px 0px;

}
.overFlowScrillHideBar{
  overflow:scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overFlowScrillHideBar::-webkit-scrollbar{
  display: none; /* Chrome, Safari, Opera*/
}
.react-datepicker {
  font-size: 3em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.idoSpan div span{
  color:#5F5F5F;
}
.airdropDeStep3 span{
  font-weight: 700;
}

.airdropFiEnd span{
  font-weight: 500;
}
.airdropFiEnd600 span{
  font-weight: 600;
}

.myAirdropViewPartic span{
  font-weight: 700;
}
.mistSpan span{
  color: #5F5F5F
}
.modifyProfileSpan span{
  font-weight: 700;
}
.transSpanWeight400 span{
  font-weight: 400;
}


.dropdown {
  position: relative;
  display: inline-block;
  border-radius: 28px;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {

  position: absolute;
  border-radius: 16px;
  background: #F8F8F8;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdownContent a {
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

table {
  width: 100%;
  border: 1px solid #444444;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #444444;
}
/* Change color of dropdown links on hover */
.dropdownContent a:hover {background-color: #9b9b9B}

/* Show the dropdown menu on hover */
//.dropdown:hover .dropdownContent {
//  display: block;
//}
//
//.dropdownContent a:visited:has(.dropdownContent) {
//  display: none;
//}

